var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-select',{staticClass:"my-input mr-2",attrs:{"disabled":"","items":_vm.allRooms,"label":_vm.$t('lbl.rooms'),"outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.room'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.mnr.room_id),callback:function ($$v) {_vm.$set(_vm.mnr, "room_id", $$v)},expression:"mnr.room_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.mnr.mnrs[0].name,"hide-details":"","disabled":""}})],1)],1),_vm._l((_vm.mnr.mnrs),function(mnmn,indMn){return [(indMn > 0)?_c('v-row',{key:indMn},[_c('v-col',{attrs:{"cols":"12","md":"4","offset-md":"8"}},[_c('v-text-field',{attrs:{"label":mnmn.name,"hide-details":"","disabled":""}})],1)],1):_vm._e()]})],2),_c('v-col',{attrs:{"cols":"12","md":"8"}},_vm._l((_vm.mnr.mnrs),function(mnmn,indMnr){return _c('v-row',{key:indMnr},[_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"11","offset-md":"1"}},[_c('v-row',{staticClass:"pt-0 mt-0"},_vm._l((mnmn.prices),function(priceMnr,indC){return _c('div',{key:indC,staticClass:"mt-2",staticStyle:{"max-width":"90px","margin-left":"20px"}},[_c('v-text-field',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"label":(_vm.politica.mnr_free && _vm.mnr.is_mnr) || (_vm.politica.inf_free && _vm.mnr.is_inf) || _vm.politica.is_tarifa
                  ? _vm.$t('lbl.price')
                  : 'Red.',"outlined":"","dense":"","hide-details":"","prefix":_vm.mnr.in_pc
                  ? ''
                  : priceMnr.price
                    ? !priceMnr.price.includes('N') && !priceMnr.price.includes('F')
                      ? '$'
                      : ''
                    : '$',"suffix":_vm.mnr.in_pc
                  ? priceMnr.price
                    ? !priceMnr.price.includes('N') && !priceMnr.price.includes('F')
                      ? '%'
                      : ''
                    : ''
                  : ''},on:{"keypress":function($event){return _vm.checkPricePc($event, priceMnr.price, _vm.mnr.in_pc)},"input":function (val) { return (priceMnr.price = priceMnr.price.toUpperCase()); }},model:{value:(priceMnr.price),callback:function ($$v) {_vm.$set(priceMnr, "price", $$v)},expression:"priceMnr.price"}})],1)}),0)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }