<template>
  <v-row class="pt-0 mt-0">
    <v-col
      cols="12"
      md="4"
    >
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <v-select
            v-model="mnr.base"
            :disabled="
              $store.state.app.onlyShow ||
                politica.na ||
                politica.is_tarifa ||
                (politica.teen_free && mnr.is_teen) ||
                (politica.mnr_free && mnr.is_mnr) ||
                (politica.inf_free && mnr.is_inf)
            "
            :items="basesFree"
            label="Base"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="slug"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Base
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            :label="mnr.name"
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-row>
        <v-col
          v-if="hasMnr"
          cols="12"
          md="1"
        >
          <span v-if="mnr.is_teen">
            <span v-if="!politica.is_tarifa && !politica.teen_free">
              %
              <br />
              <v-switch
                v-model="mnr.in_pc"
                hide-details
                class="pt-0 mt-0"
              ></v-switch>
            </span>
          </span>
          <span v-else-if="mnr.is_mnr">
            <span v-if="!politica.is_tarifa && !politica.mnr_free">
              %
              <br />
              <v-switch
                v-model="mnr.in_pc"
                hide-details
                class="pt-0 mt-0"
              ></v-switch>
            </span>
          </span>
          <span v-else-if="mnr.is_inf">
            <span v-if="!politica.is_tarifa && !politica.inf_free">
              %
              <br />
              <v-switch
                v-model="mnr.in_pc"
                hide-details
                class="pt-0 mt-0"
              ></v-switch>
            </span>
          </span>
        </v-col>
        <v-col
          cols="12"
          md="11"
          class="pt-0 mt-0"
        >
          <v-row class="pt-0 mt-0">
            <div
              v-for="(priceMnr, indC) in mnr.prices"
              :key="indC"
              style="max-width: 90px; margin-left: 20px"
              class="mt-2"
            >
              <v-text-field
                v-model="priceMnr.price"
                :disabled="$store.state.app.onlyShow"
                :append-icon="indC === 0 ? 'mdi-arrow-right' : ''"
                :label="
                  (politica.mnr_free && mnr.is_mnr) || (politica.inf_free && mnr.is_inf) || politica.is_tarifa
                    ? $t('lbl.price')
                    : 'Red.'
                "
                outlined
                dense
                hide-details
                :prefix="
                  mnr.in_pc
                    ? ''
                    : priceMnr.price
                      ? !priceMnr.price.includes('N') && !priceMnr.price.includes('F')
                        ? '$'
                        : ''
                      : '$'
                "
                :suffix="
                  mnr.in_pc
                    ? priceMnr.price
                      ? !priceMnr.price.includes('N') && !priceMnr.price.includes('F')
                        ? '%'
                        : ''
                      : ''
                    : ''
                "
                @keypress="checkPricePc($event, priceMnr.price, mnr.in_pc)"
                @input="val => (priceMnr.price = priceMnr.price.toUpperCase())"
                @click:append="
                  indC === 0
                    ? replicaPricePoliticaChildSeasonContrateHotel({
                      posPolitica: posPolitica,
                      posMnr: pos,
                      price: priceMnr.price,
                    })
                    : ''
                "
              ></v-text-field>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    politica: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    mnr: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    posPolitica: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    bases: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      suplementsSeasonContrateHotel: state => state.app.suplementsSeasonContrateHotel,
      politicasChildSeasonContrateHotel: state => state.app.politicasChildSeasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
    }),

    hasTeen() {
      let has = false
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_teen) {
          has = true
        }
      })

      return has
    },
    hasMnr() {
      let has = false
      this.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_teen || element.has_a_mnr || element.has_a_inf) {
          has = true
        }
      })

      return has
    },
    basesFree() {
      const arr = []
      this.bases.forEach(element => {
        if (this.politica.inf_free && this.mnr.is_inf) {
          if (element.slug === 'N/A') {
            arr.push(element)
          }
        } else if (this.politica.mnr_free && this.mnr.is_mnr) {
          if (element.slug === 'N/A') {
            arr.push(element)
          }
        } else if (this.politica.teen_free && this.mnr.is_teen) {
          if (element.slug === 'N/A') {
            arr.push(element)
          }
        } else if (this.politica.na) {
          if (element.slug === 'N/A') {
            arr.push(element)
          }
        } else if (this.politica.is_tarifa) {
          if (element.slug === 'N/A') {
            arr.push(element)
          }
        } else if (element.slug !== 'N/A') {
          arr.push(element)
        }
      })

      return arr
    },
  },
  methods: {
    ...mapMutations([
      'deleteSuplementsAlimenticiosSeasonContrateHotel',
      'addSuplementsAlimenticiosSeasonContrateHotel',
      'replicaSuplementsAlimenticiosSeasonContrateHotel',
      'replicaPricePoliticaChildSeasonContrateHotel',
    ]),
    checkPricePc(elEvento, model, inPc) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/AaFfRrEe'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (inPc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!inPc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (elEvento.key === '.' && model.length === 0) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES F Y YA ESTA INCLUIDA
      else if (model.includes('F') && (elEvento.key === 'F' || elEvento.key === 'f')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'F' || elEvento.key === 'f') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (!model.includes('F') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (model.includes('R') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if ((!model.includes('F') || !model.includes('R')) && (elEvento.key === 'E' || elEvento.key === 'e')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if (model.includes('F') && model.includes('R') && (elEvento.key === 'E' || elEvento.key === 'e')) {
        const spliter = model.split('')
        let cant = 0
        spliter.forEach(element => {
          if (element === 'E') {
            // eslint-disable-next-line no-plusplus
            cant++
          }
        })

        if (cant === 2) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },
  },
}
</script>
