<template>
  <v-col
    cols="12"
    class="pt-0 mt-0"
  >
    <v-row class="pt-0 mt-0">
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="room.rooms_id"
          :disabled="$store.state.app.onlyShow"
          :items="roomsFree"
          :label="$t('lbl.rooms')"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          multiple
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.room') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>

      <v-col
        v-if="!suplement.is_tarifa"
        cols="12"
        md="1"
      >
        <v-tooltip
          v-if="suplement.rooms.length > 1 && !$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1"
              fab
              dark
              x-small
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="deleteRoomSuplementsReduccTarifasSeasonContrateHotel({ posSup: posSuplement, posRoom: pos })"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="!$store.state.app.onlyShow && pos === 0"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1 ml-2"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              :disabled="verifyRoomsFree"
              v-on="on"
              @click="addRoomSuplementsReduccTarifasSeasonContrateHotel(posSuplement)"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </v-col>
      <v-col
        v-if="suplement.is_tarifa"
        cols="12"
        md="1"
      >
        <v-switch
          v-model="room.is_main"
          label="Main"
          hide-details
          class="pt-0 mt-4"
          @click="setIsMain"
        ></v-switch>
      </v-col>

      <v-col
        cols="12"
        md="1"
        style="text-align: center"
      >
        <b v-if="!room.is_main">%</b><br />
        <v-switch
          v-if="!room.is_main"
          v-model="room.in_pc"
          hide-details
          class="pt-0 ml-5 mt-0"
        ></v-switch>
      </v-col>

      <v-col
        cols="12"
        md="7"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-row>
              <div
                v-for="(price, indC) in room.prices"
                :key="indC"
                style="max-width: 90px; margin-left: 20px"
                class="mt-2"
              >
                <v-text-field
                  v-model="price.price"
                  :disabled="$store.state.app.onlyShow"
                  :label="
                    suplement.is_tarifa
                      ? room.is_main
                        ? 'Precio'
                        : 'Suplemento'
                      : suplement.is_reduction
                        ? 'Red.'
                        : 'Sup.'
                  "
                  :append-icon="indC === 0 && !room.is_main ? 'mdi-arrow-right' : ''"
                  outlined
                  dense
                  hide-details
                  :prefix="room.in_pc ? '' : price.price ? (!price.price.includes('N') ? '$' : '') : '$'"
                  :suffix="room.in_pc ? (price.price ? (!price.price.includes('N') ? '%' : '') : '') : ''"
                  @keypress="checkPricePc($event, price.price)"
                  @input="val => (price.price = price.price.toUpperCase())"
                  @click:append="
                    indC === 0 && !room.is_main
                      ? replicaPriceSuplementTarifaSeasonContrateHotel({
                        posSuplement: posSuplement,
                        posRoom: pos,
                        price: price.price,
                      })
                      : ''
                  "
                ></v-text-field>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

import SeasonCuposOcupationHotel from './SeasonCuposOcupationHotel.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SeasonCuposOcupationHotel,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    suplement: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posSuplement: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      show: false,
      ruleAdlMin: [v => !!v || 'Campo requerido', v => this.isOneOrZero(v) || 'Valor permitido 0 o 1'],
      ruleMnrMax: [v => !!v || 'Campo requerido', v => this.isInterger(v) || 'Valor debe ser >= 0'],
      rulePaxMax: [v => !!v || 'Campo requerido', v => this.isNumber(v) || 'Valor debe ser > 0'],
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      suplementsReduccTarifasSeasonContrateHotel: state => state.app.suplementsReduccTarifasSeasonContrateHotel,
    }),
    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        if (element) {
          let esta = false
          let estaIncluida = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsReduccTarifasSeasonContrateHotel.length; index++) {
            if (this.suplement.is_suplement && this.suplementsReduccTarifasSeasonContrateHotel[index].is_suplement) {
              if (
                JSON.stringify(this.suplementsReduccTarifasSeasonContrateHotel[index].sup_red_id)
                === JSON.stringify(this.suplement.sup_red_id)
              ) {
                // eslint-disable-next-line no-plusplus
                for (let j = 0; j < this.suplementsReduccTarifasSeasonContrateHotel[index].rooms.length; j++) {
                  const roomSup = this.suplementsReduccTarifasSeasonContrateHotel[index].rooms[j]
                  if (roomSup.rooms_id.includes(element.id)) {
                    if (index === this.posSuplement && j !== this.pos) {
                      esta = true
                    } else if (index !== this.posSuplement && j === this.pos) {
                      esta = true
                    } else if (index !== this.posSuplement && j !== this.pos) {
                      esta = true
                    }
                  }
                }
              }
            } else if (
              this.suplement.is_reduction
              && this.suplementsReduccTarifasSeasonContrateHotel[index].is_reduction
            ) {
              if (
                JSON.stringify(this.suplementsReduccTarifasSeasonContrateHotel[index].sup_red_id)
                === JSON.stringify(this.suplement.sup_red_id)
              ) {
                // eslint-disable-next-line no-plusplus
                for (let j = 0; j < this.suplementsReduccTarifasSeasonContrateHotel[index].rooms.length; j++) {
                  const roomSup = this.suplementsReduccTarifasSeasonContrateHotel[index].rooms[j]
                  if (roomSup.rooms_id.includes(element.id)) {
                    if (index === this.posSuplement && j !== this.pos) {
                      esta = true
                    } else if (index !== this.posSuplement && j === this.pos) {
                      esta = true
                    } else if (index !== this.posSuplement && j !== this.pos) {
                      esta = true
                    }
                  }
                }
              }
            }
          }
          if (!esta) {
            if (this.room.rooms_id.length > 0) {
              this.roomsSeasonContrateHotel.forEach(rSeason => {
                if (rSeason.rooms_id.includes(element.id) && rSeason.rooms_id.includes(this.room.rooms_id[0])) {
                  estaIncluida = true
                }
              })

              if (!estaIncluida) {
                result.push({
                  ...element,

                  // disabled: true,
                })
              } else {
                result.push(element)
              }
            } else {
              result.push(element)
            }
          }
        }
      })

      return result
    },
    verifyRoomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        if (element) {
          let esta = false
          let estaIncluida = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsReduccTarifasSeasonContrateHotel.length; index++) {
            if (this.suplement.is_suplement && this.suplementsReduccTarifasSeasonContrateHotel[index].is_suplement) {
              if (
                JSON.stringify(this.suplementsReduccTarifasSeasonContrateHotel[index].sup_red_id)
                === JSON.stringify(this.suplement.sup_red_id)
              ) {
                // eslint-disable-next-line no-plusplus
                for (let j = 0; j < this.suplementsReduccTarifasSeasonContrateHotel[index].rooms.length; j++) {
                  const roomSup = this.suplementsReduccTarifasSeasonContrateHotel[index].rooms[j]
                  if (roomSup.rooms_id.includes(element.id)) {
                    if (index === this.posSuplement) {
                      esta = true
                    }
                  }
                }
              }
            } else if (
              this.suplement.is_reduction
              && this.suplementsReduccTarifasSeasonContrateHotel[index].is_reduction
            ) {
              if (
                JSON.stringify(this.suplementsReduccTarifasSeasonContrateHotel[index].sup_red_id)
                === JSON.stringify(this.suplement.sup_red_id)
              ) {
                // eslint-disable-next-line no-plusplus
                for (let j = 0; j < this.suplementsReduccTarifasSeasonContrateHotel[index].rooms.length; j++) {
                  const roomSup = this.suplementsReduccTarifasSeasonContrateHotel[index].rooms[j]
                  if (roomSup.rooms_id.includes(element.id)) {
                    if (index === this.posSuplement) {
                      esta = true
                    }
                  }
                }
              }
            }
          }
          if (!esta) {
            if (this.room.rooms_id.length > 0) {
              this.roomsSeasonContrateHotel.forEach(rSeason => {
                if (rSeason.rooms_id.includes(element.id) && rSeason.rooms_id.includes(this.room.rooms_id[0])) {
                  estaIncluida = true
                }
              })

              if (!estaIncluida) {
                result.push({
                  ...element,

                  // disabled: true,
                })
              } else {
                result.push(element)
              }
            } else {
              result.push(element)
            }
          }
        }
      })

      if (result.length === 0) {
        return true
      } if (roomsHotel.length === this.suplementsReduccTarifasSeasonContrateHotel[this.posSuplement].rooms.length) {
        return true
      }

      return false
    },
  },
  watch: {
    searchRooms(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    if (this.roomsHotel.length === 1) {
      this.room.room_id = this.roomsHotel[0].id
    }

    if (this.roomsSeasonContrateHotel.length === 1) {
      if (this.roomsSeasonContrateHotel[0].rooms_id.length === 1) {
        this.room.rooms_id = this.roomsSeasonContrateHotel[0].rooms_id
      }
    }
  },
  methods: {
    ...mapMutations([
      'deleteRoomSuplementsReduccTarifasSeasonContrateHotel',
      'addRoomSuplementsReduccTarifasSeasonContrateHotel',
      'replicaPriceSuplementTarifaSeasonContrateHotel',
    ]),
    filterRooms() {
      const rooms = this.roomsHotel.filter(e => e.name.toLowerCase())
      rooms.forEach(element => {
        let esta = false
        this.roomsSeasonContrateHotel.forEach(rrr => {
          if (rrr.room_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsRooms.push(element)
        }
      })
    },
    checkPricePc(elEvento, model) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/Aa'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (this.room.in_pc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!this.room.in_pc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (
        elEvento.key === '.'
        && (model.length === 0 || model.includes('N') || model.includes('/') || model.includes('A'))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if ((model) && (model.includes('.') && elEvento.key === '.')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if ((model) && (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if ((model) && (model.includes('/') && elEvento.key === '/')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if ((model) && (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if ((model) && (model.includes('N') || model.includes('/') || model.includes('A'))) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },
    setIsMain() {
      if (this.room.is_main) {
        this.room.in_pc = false
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 10px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
</style>
