var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-select',{staticClass:"my-input mr-2",attrs:{"disabled":_vm.$store.state.app.onlyShow,"items":_vm.roomsFree,"label":_vm.$t('lbl.rooms'),"outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id","multiple":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.room'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.room.rooms_id),callback:function ($$v) {_vm.$set(_vm.room, "rooms_id", $$v)},expression:"room.rooms_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.cuposRoomsSeasonContrateHotel.length > 1 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteCupoRoomSeasonContrateHotel(_vm.pos)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,2262301237)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(!_vm.$store.state.app.onlyShow && _vm.pos === 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 ml-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary","disabled":_vm.verifyRoomsFree},on:{"click":_vm.addCupoRoomSeasonContrateHotel}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,3816398190)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',_vm._l((_vm.room.cupos),function(cupo,indC){return _c('div',{key:indC,staticClass:"mt-2",staticStyle:{"max-width":"90px","margin-left":"20px"}},[_c('v-text-field',{staticClass:"my-input",attrs:{"append-icon":indC === 0 && _vm.room.cupos.length > 1 ? 'mdi-arrow-right' : '',"disabled":_vm.$store.state.app.onlyShow,"label":_vm.$t('lbl.cupo'),"outlined":"","dense":"","hide-details":""},on:{"keypress":function($event){return _vm.checkCupo($event, cupo.cupo, indC)},"input":function (val) { return (cupo.cupo = cupo.cupo.toUpperCase()); },"click:append":function($event){indC === 0
                ? _vm.replicaCupoSeasonContrateHotel({
                  posRoom: _vm.pos,
                  cupo: cupo.cupo,
                })
                : ''}},model:{value:(cupo.cupo),callback:function ($$v) {_vm.$set(cupo, "cupo", $$v)},expression:"cupo.cupo"}})],1)}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }