var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-select',{staticClass:"my-input mr-2",attrs:{"disabled":_vm.$store.state.app.onlyShow,"items":_vm.roomsFree,"label":_vm.$t('lbl.room'),"outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.setRooms()},"click":function($event){$event.preventDefault();return _vm.setRoomsNative()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.room'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.room.room_id),callback:function ($$v) {_vm.$set(_vm.room, "room_id", $$v)},expression:"room.room_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{staticClass:"pt-0 mt-4",attrs:{"label":"Main","hide-details":""},on:{"click":_vm.setIsMain},model:{value:(_vm.room.is_main),callback:function ($$v) {_vm.$set(_vm.room, "is_main", $$v)},expression:"room.is_main"}})],1)],1)],1),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"12","md":"1"}},[(!_vm.room.is_main)?_c('span',[_c('span',[_vm._v("%")]),_c('br'),_c('v-switch',{staticClass:"pt-0 mt-0 ml-15",attrs:{"hide-details":""},model:{value:(_vm.room.in_pc),callback:function ($$v) {_vm.$set(_vm.room, "in_pc", $$v)},expression:"room.in_pc"}})],1):_vm._e()]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',_vm._l((_vm.room.prices),function(price,indC){return _c('div',{key:indC,staticClass:"mt-2",staticStyle:{"max-width":"90px","margin-left":"20px"}},[_c('v-text-field',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"append-icon":indC === 0 && !_vm.room.is_main ? 'mdi-arrow-right' : '',"label":("" + (!_vm.room.is_main ? 'Suplemento' : ((_vm.$t('lbl.price')) + " DBL"))),"outlined":"","dense":"","hide-details":"","prefix":_vm.room.in_pc
              ? ''
              : price.price
                ? !price.price.includes('N') && !price.price.includes('F')
                  ? '$'
                  : ''
                : '$',"suffix":_vm.room.in_pc
              ? price.price
                ? price.price.includes('N') || price.price.includes('F')
                  ? ''
                  : '%'
                : '%'
              : ''},on:{"click:append":function($event){indC === 0 && !_vm.room.is_main
              ? _vm.replicaPriceNoMainSeasonContrateHotel({
                posRoom: _vm.pos,
                price: price.price,
              })
              : ''},"keypress":function($event){return _vm.checkPrice($event, price.price)},"input":function (val) { return (price.price = price.price.toUpperCase()); }},model:{value:(price.price),callback:function ($$v) {_vm.$set(price, "price", $$v)},expression:"price.price"}})],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }