<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="ocupation.adult"
        :disabled="$store.state.app.onlyShow"
        :label="`Max. ${$t('lbl.adultos')}`"
        outlined
        dense
        type="number"
        hide-details
        @input="changePax"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="ocupation.child"
        :disabled="$store.state.app.onlyShow"
        :label="`Max. ${$t('lbl.childs')}`"
        outlined
        dense
        type="number"
        hide-details
        @input="changePax"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="ocupation.pax"
        label="Pax"
        outlined
        dense
        type="number"
        hide-details
        disabled
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-tooltip
        v-if="!$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1"
            fab
            dark
            x-small
            color="error"
            v-bind="attrs"
            v-on="on"
            @click="deleteOcupationRoomSeasonContrateHotel({ posRoom: posRoom, posOcupation: pos })"
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>

      <v-tooltip
        v-if="!$store.state.app.onlyShow && pos === 0"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 ml-2"
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="addOcupationRoomSeasonContrateHotel(posRoom)"
          >
            <v-icon small>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    ocupation: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posRoom: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
    }),
  },
  methods: {
    ...mapMutations(['addOcupationRoomSeasonContrateHotel', 'deleteOcupationRoomSeasonContrateHotel']),
    changePax() {
      let pax = 0
      if (this.ocupation.adult) {
        pax += parseInt(this.ocupation.adult, 10)
      }
      if (this.ocupation.child) {
        pax += parseInt(this.ocupation.child, 10)
      }

      this.ocupation.pax = pax
    },
  },
}
</script>
