<template>
  <v-col
    cols="12"
    class="pt-0 mt-0"
  >
    <v-row class="pt-0 mt-0">
      <v-col
        v-if="showOptional"
        cols="12"
        md="2"
        class="pt-0 mt-0 text-right"
        style="text-align: right"
      >
        <v-switch
          v-model="suplement.optional"
          :label="$t('lbl.optional')"
          hide-details
          class="pt-0 mt-0"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="1"
        :offset-md="showOptional ? 0 : 2"
        class="pt-0 mt-0 text-right"
        style="text-align: right"
      >
        <v-switch
          v-model="suplement.is_tarifa"
          :label="$t('lbl.tarifa')"
          hide-details
          class="pt-0 mt-0"
          @click="from === 'supRedTar' ? setTarifa() : ''"
        ></v-switch>
      </v-col>
      <v-col
        cols="1"
        class="pt-0 mt-0"
      ></v-col>
      <v-col
        cols="12"
        md="7"
        offset-md="1"
        class="pt-0 mt-0"
      >
        <v-row class="pt-0 mt-0">
          <v-col
            v-for="(season, indR) in seasonsContrateHotel"
            :key="indR"
            cols="12"
            md="2"
            class="pt-0 mt-0"
          >
            <v-text-field
              v-model="season.name"
              class="my-input"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    suplement: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    showOptional: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    from: {
      type: String,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      allRooms: [],
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
    }
  },
  computed: {
    ...mapState({
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
      seasonContrateHotel: state => state.app.seasonContrateHotel,
    }),
  },
  methods: {
    ...mapMutations([
      'setTarifaSuplementsReduccTarifasSeasonContrateHotel',
      'setSuplementsReduccTarifasOnSeasonContrateHotel',
    ]),
    setTarifa() {
      this.setTarifaSuplementsReduccTarifasSeasonContrateHotel(this.pos)
      this.setSuplementsReduccTarifasOnSeasonContrateHotel(this.pos)
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
