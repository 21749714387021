<template>
  <v-col cols="12">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="room.rooms_id"
          :disabled="$store.state.app.onlyShow"
          :items="roomsFree"
          :label="$t('lbl.rooms')"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          multiple
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.room') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-tooltip
              v-if="suplement.cena_rooms.length > 1 && !$store.state.app.onlyShow"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1"
                  fab
                  dark
                  x-small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteRoomSuplementsOtrosCenaSeasonContrateHotel({ posSup: posSuplement, posRoom: pos })"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.delete') }}</span>
            </v-tooltip>

            <v-tooltip
              v-if="!$store.state.app.onlyShow && pos === 0"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1 ml-2"
                  fab
                  dark
                  x-small
                  color="primary"
                  v-bind="attrs"
                  :disabled="verifyRoomsFree"
                  v-on="on"
                  @click="addRoomSuplementsOtrosCenaSeasonContrateHotel(posSuplement)"
                >
                  <v-icon small>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.insert') }}</span>
            </v-tooltip>
          </v-col>

          <v-col
            cols="12"
            md="8"
            offset-md="1"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-if="room.rooms_id.length > 0"
                  v-model="room.plan_id"
                  :disabled="$store.state.app.onlyShow"
                  :items="plansFree"
                  :label="$t('lbl.plan')"
                  outlined
                  dense
                  hide-details
                  item-text="siglas"
                  item-value="id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.plan') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="8"
              >
                <v-row v-if="room.rooms_id.length > 0">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="room.price_adl"
                      :disabled="$store.state.app.onlyShow"
                      label="ADL"
                      outlined
                      dense
                      hide-details
                      :append-icon="hasTeen || hasMnr ? 'mdi-arrow-right' : ''"
                      :prefix="room.price_adl ? (!room.price_adl.includes('N') ? '$' : '') : '$'"
                      @keypress="checkPricePc($event, room.price_adl)"
                      @input="val => (room.price_adl = room.price_adl.toUpperCase())"
                      @click:append="
                        hasTeen || hasMnr
                          ? replicaSuplementsOtrosCenaSeasonContrateHotel({
                            posSuplement: posSuplement,
                            posSeason: posSeason,
                            posRoom: pos,
                            price: room.price_adl,
                          })
                          : ''
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="hasTeen || hasMnr"
                    cols="12"
                    md="2"
                  >
                    <span v-if="!suplement.is_tarifa">%</span>
                    <br />
                    <v-switch
                      v-if="!suplement.is_tarifa"
                      v-model="room.in_pc"
                      hide-details
                      class="pt-0 mt-0"
                    ></v-switch>
                  </v-col>
                  <v-col
                    v-if="hasTeen"
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="room.price_teen"
                      :disabled="$store.state.app.onlyShow"
                      label="Teen"
                      outlined
                      dense
                      hide-details
                      :prefix="
                        room.in_pc
                          ? ''
                          : room.price_teen
                            ? !room.price_teen.includes('N') && !room.price_teen.includes('F')
                              ? '$'
                              : ''
                            : '$'
                      "
                      :suffix="
                        room.in_pc
                          ? room.price_teen
                            ? room.price_teen.includes('N') || room.price_teen.includes('F')
                              ? ''
                              : '%'
                            : '%'
                          : ''
                      "
                      @keypress="checkPriceMr($event, room.price_teen)"
                      @input="val => (room.price_teen = room.price_teen.toUpperCase())"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="hasMnr"
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="room.price_mnr"
                      :disabled="$store.state.app.onlyShow"
                      label="MNR"
                      outlined
                      dense
                      hide-details
                      :prefix="
                        room.in_pc
                          ? ''
                          : room.price_mnr
                            ? !room.price_mnr.includes('N') && !room.price_mnr.includes('F')
                              ? '$'
                              : ''
                            : '$'
                      "
                      :suffix="
                        room.in_pc
                          ? room.price_mnr
                            ? room.price_mnr.includes('N') || room.price_mnr.includes('F')
                              ? ''
                              : '%'
                            : '%'
                          : ''
                      "
                      @keypress="checkPriceMr($event, room.price_mnr)"
                      @input="val => (room.price_mnr = room.price_mnr.toUpperCase())"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="hasMnr"
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="room.price_inf"
                      :disabled="$store.state.app.onlyShow"
                      label="INF"
                      outlined
                      dense
                      hide-details
                      :prefix="
                        room.in_pc
                          ? ''
                          : room.price_inf
                            ? !room.price_inf.includes('N') && !room.price_inf.includes('F')
                              ? '$'
                              : ''
                            : '$'
                      "
                      :suffix="
                        room.in_pc
                          ? room.price_inf
                            ? room.price_inf.includes('N') || room.price_inf.includes('F')
                              ? ''
                              : '%'
                            : '%'
                          : ''
                      "
                      @keypress="checkPriceMr($event, room.price_inf)"
                      @input="val => (room.price_inf = room.price_inf.toUpperCase())"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    suplement: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posSuplement: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allPlanes: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
      show: false,
      ruleAdlMin: [v => !!v || 'Campo requerido', v => this.isOneOrZero(v) || 'Valor permitido 0 o 1'],
      ruleMnrMax: [v => !!v || 'Campo requerido', v => this.isInterger(v) || 'Valor debe ser >= 0'],
      rulePaxMax: [v => !!v || 'Campo requerido', v => this.isNumber(v) || 'Valor debe ser > 0'],
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      suplementsOtrosSeasonContrateHotel: state => state.app.suplementsOtrosSeasonContrateHotel,
    }),
    plansFree() {
      const result = []
      const planSelect = []
      const planHotel = []

      /* planSelect.push(-1)
      planHotel.push({
        name: 'Todos',
        siglas: 'Todos',
        id: -1,
      }) */
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        if (!planSelect.includes(rSeason.plan_id)) {
          planSelect.push(rSeason.plan_id)
          planHotel.push(this.allPlanes.filter(e => e.id === rSeason.plan_id)[0])
        }
      })

      planHotel.forEach(element => {
        result.push(element)
      })

      return result
    },
    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []

      roomsSelect.push(-1)
      roomsHotel.push({
        name: 'Todas',
        id: -1,
      })
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        if (element) {
          let esta = false
          let estaIncluida = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsOtrosSeasonContrateHotel.length; index++) {
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.suplementsOtrosSeasonContrateHotel[index].cena_rooms.length; j++) {
              const roomSup = this.suplementsOtrosSeasonContrateHotel[index].cena_rooms[j]
              if (roomSup.rooms_id.includes(element.id)) {
                if (index === this.posSuplement) {
                  if (j !== this.pos) {
                    esta = true
                  }
                }

                /* if (index === this.posSuplement && j !== this.pos) {
                  esta = true
                } else if (index !== this.posSuplement && j === this.pos) {
                  esta = true
                } else if (index !== this.posSuplement && j !== this.pos) {
                  esta = true
                } */
              }
            }
          }

          if (!esta) {
            if (this.room.rooms_id.includes(-1)) {
              if (element.id !== -1) {
                result.push({
                  ...element,
                  disabled: true,
                })
              } else {
                result.push(element)
              }
            } else if (this.room.rooms_id.length > 0) {
              this.roomsSeasonContrateHotel.forEach(rSeason => {
                if (rSeason.rooms_id.includes(element.id) && rSeason.rooms_id.includes(this.room.rooms_id[0])) {
                  estaIncluida = true
                }
              })

              if (!estaIncluida) {
                result.push({
                  ...element,
                  disabled: true,
                })
              } else {
                result.push(element)
              }
            } else {
              result.push(element)
            }
          }
        }
      })

      return result
    },
    verifyRoomsFree() {
      const result = []
      const roomsSelect = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
          }
        })
      })

      roomsSelect.forEach(element => {
        if (element) {
          let esta = false
          let estaIncluida = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.suplementsOtrosSeasonContrateHotel.length; index++) {
            if (index === this.posSuplement) {
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < this.suplementsOtrosSeasonContrateHotel[index].cena_rooms.length; j++) {
                if (this.suplementsOtrosSeasonContrateHotel[index].cena_rooms[j].rooms_id.includes(element)) {
                  esta = true
                }
              }
            }
          }
          if (!esta) {
            if (this.room.rooms_id.length > 0) {
              this.roomsSeasonContrateHotel.forEach(rSeason => {
                if (rSeason.rooms_id.includes(element) && rSeason.rooms_id.includes(this.room.rooms_id[0])) {
                  estaIncluida = true
                }
              })

              if (!estaIncluida) {
                result.push({
                  ...element,
                  disabled: true,
                })
              } else {
                result.push(element)
              }
            } else {
              result.push(element)
            }
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },
    hasTeen() {
      let has = false
      if (this.room.rooms_id.length > 0) {
        if (this.room.rooms_id.includes(-1)) {
          this.roomsSeasonContrateHotel.forEach(element => {
            if (element.has_a_teen) {
              has = true
            }
          })
        } else {
          this.roomsSeasonContrateHotel.forEach(element => {
            if (element.rooms_id.includes(this.room.rooms_id[0])) {
              if (element.has_a_teen) {
                has = true
              }
            }
          })
        }
      }

      return has
    },
    hasMnr() {
      let has = false
      if (this.room.rooms_id.length > 0) {
        if (this.room.rooms_id.includes(-1)) {
          this.roomsSeasonContrateHotel.forEach(element => {
            if (element.has_a_mnr || element.has_a_inf) {
              has = true
            }
          })
        } else {
          this.roomsSeasonContrateHotel.forEach(element => {
            if (element.rooms_id.includes(this.room.rooms_id[0])) {
              if (element.has_a_mnr || element.has_a_inf) {
                has = true
              }
            }
          })
        }
      }

      return has
    },
    posSeason() {
      let pos = -1
      if (this.room.rooms_id.length > 0) {
        if (!this.room.rooms_id.includes(-1)) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.roomsSeasonContrateHotel.length; index++) {
            const element = this.roomsSeasonContrateHotel[index]
            if (element.rooms_id.includes(this.room.rooms_id[0])) {
              pos = index
            }
          }
        }
      }

      return pos
    },
  },
  watch: {
    searchRooms(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    if (this.roomsHotel.length === 1) {
      this.room.room_id = this.roomsHotel[0].id
    }

    if (this.roomsSeasonContrateHotel.length === 1) {
      if (this.roomsSeasonContrateHotel[0].rooms_id.length === 1) {
        this.room.rooms_id = this.roomsSeasonContrateHotel[0].rooms_id
      }
    }
  },
  methods: {
    ...mapMutations([
      'deleteRoomSuplementsOtrosCenaSeasonContrateHotel',
      'addRoomSuplementsOtrosCenaSeasonContrateHotel',
      'replicaSuplementsOtrosCenaSeasonContrateHotel',
    ]),
    filterRooms() {
      const rooms = this.roomsHotel.filter(e => e.name.toLowerCase())
      rooms.forEach(element => {
        let esta = false
        this.roomsSeasonContrateHotel.forEach(rrr => {
          if (rrr.room_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsRooms.push(element)
        }
      })
    },
    checkPricePc(elEvento, model) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/Aa'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (this.room.in_pc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!this.room.in_pc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (
        elEvento.key === '.'
        && (model.length === 0 || model.includes('N') || model.includes('/') || model.includes('A'))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (model.includes('N') || model.includes('/') || model.includes('A')) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },
    checkPriceMr(elEvento, model) {
      const numeros = '0123456789.'
      const caracteres = 'Nn/AaFfRrEe'
      const numerosCaracteres = numeros + caracteres

      // VERIFICAR EL TAMAÑO DEL VALOR
      if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (this.room.in_pc) {
          if (parseFloat(`${model}${elEvento.key}`) > 100) {
            elEvento.preventDefault()
          }
        } else if (!this.room.in_pc) {
          if (elEvento.keyCode === 48 && model.length === 0) {
            elEvento.preventDefault()
          }
        }
      }

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y ESTA N/A O NO HAY NADA ESCRITO
      else if (
        elEvento.key === '.'
        && (model.length === 0
          || model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E'))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES . Y YA ESTA INCLUIDO EL .
      else if (model.includes('.') && elEvento.key === '.') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES F Y YA ESTA INCLUIDA
      else if (model.includes('F') && (elEvento.key === 'F' || elEvento.key === 'f')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'F' || elEvento.key === 'f') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (!model.includes('F') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES R Y YA ESTA INCLUIDA
      else if (model.includes('R') && (elEvento.key === 'R' || elEvento.key === 'r')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if ((!model.includes('F') || !model.includes('R')) && (elEvento.key === 'E' || elEvento.key === 'e')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES E Y YA ESTA INCLUIDA
      else if (model.includes('F') && model.includes('R') && (elEvento.key === 'E' || elEvento.key === 'e')) {
        const spliter = model.split('')
        let cant = 0
        spliter.forEach(element => {
          if (element === 'E') {
            // eslint-disable-next-line no-plusplus
            cant++
          }
        })

        if (cant === 2) {
          // No se agrega
          elEvento.preventDefault()
        }
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES UN NUMERO DESPUES D UNA LETRA
      else if (elEvento.keyCode >= 48 && elEvento.keyCode <= 57) {
        if (
          model.includes('N')
          || model.includes('/')
          || model.includes('A')
          || model.includes('F')
          || model.includes('R')
          || model.includes('E')
        ) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 10px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
</style>
