var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{class:_vm.pos > 0 ? 'mt-10' : ''},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"items":_vm.suplementsReductionsFree,"placeholder":"Sup / Red","label":_vm.suplement.is_tarifa
                ? _vm.$t('lbl.tarif')
                : _vm.suplement.is_suplement
                  ? _vm.$t('lbl.suplemento')
                  : _vm.suplement.is_reduction
                    ? _vm.$t('lbl.reduccion')
                    : '',"outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id","multiple":""},on:{"change":_vm.setSuplement},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.suplemento'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.suplement.sup_red_id),callback:function ($$v) {_vm.$set(_vm.suplement, "sup_red_id", $$v)},expression:"suplement.sup_red_id"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.suplementsReduccTarifasSeasonContrateHotel.length > 1 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mt-1",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteSuplementsReduccTarifasSeasonContrateHotel(_vm.pos)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,1755254098)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e(),(!_vm.$store.state.app.onlyShow && _vm.pos === 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 ml-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary","disabled":_vm.verifyRoomsFree},on:{"click":_vm.addSuplementsReduccTarifasSeasonContrateHotel}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,104570490)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"7","offset-md":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[(!_vm.suplement.is_tarifa && _vm.slugSuplement !== 'habitacion-de-uso-individual')?_c('v-select',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"items":_vm.bases,"label":"Base","outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"slug"},on:{"change":_vm.setBase},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Base ")])],1)]},proxy:true}],null,false,1177353156),model:{value:(_vm.suplement.base),callback:function ($$v) {_vm.$set(_vm.suplement, "base", $$v)},expression:"suplement.base"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"8"}},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.equipaje_personal.para'))+": ")]),_c('v-radio-group',{staticClass:"pt-0 mt-0",attrs:{"row":""},model:{value:(_vm.suplement.applyBy),callback:function ($$v) {_vm.$set(_vm.suplement, "applyBy", $$v)},expression:"suplement.applyBy"}},_vm._l((_vm.apply),function(ap,ind){return _c('v-radio',{key:ind,attrs:{"label":ap.name,"value":ap.slug,"disabled":(_vm.slugSuplement === 'habitacion-de-uso-individual' && ap.slug !== 'pphn')
                  ||
                  (_vm.slugSuplement === '3er-pax' && ap.slug !== 'pppn' && ap.slug !== 'pphn')}})}),1)],1)],1)],1)],1),_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{staticClass:"pt-0 mt-0 text-right",staticStyle:{"text-align":"right"},attrs:{"cols":"12","md":"1","offset-md":"2"}},[_c('v-switch',{staticClass:"pt-0 mt-0",attrs:{"label":_vm.$t('lbl.prices'),"hide-details":""},on:{"click":_vm.setTarifa},model:{value:(_vm.suplement.is_tarifa),callback:function ($$v) {_vm.$set(_vm.suplement, "is_tarifa", $$v)},expression:"suplement.is_tarifa"}})],1),_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"1"}}),_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"7","offset-md":"1"}},[_c('v-row',{staticClass:"pt-0 mt-0"},_vm._l((_vm.seasonsContrateHotel),function(season,indR){return _c('div',{key:indR,staticClass:"pt-0 mt-0",staticStyle:{"max-width":"90px","margin-left":"20px","margin-bottom":"10px"}},[_c('v-text-field',{staticClass:"my-input",attrs:{"hide-details":"","disabled":""},model:{value:(season.name),callback:function ($$v) {_vm.$set(season, "name", $$v)},expression:"season.name"}})],1)}),0)],1)],1)],1)],1),_c('v-row',{staticClass:"pt-0 mt-0"},_vm._l((_vm.suplement.rooms),function(room,indR){return _c('SeasonSuplementsReduccionsTarifasHotelRooms',{key:indR,attrs:{"pos":indR,"pos-suplement":_vm.pos,"hotel":_vm.hotel,"room":room,"suplement":_vm.suplement,"all-rooms":_vm.allRooms}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }