<template>
  <v-col cols="12">
    <v-row>
      <v-col
        cols="12"
        md="4"
        class="mt-0"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-radio-group
              v-if="showPPP"
              v-model="seasonContrateHotel.price_estancia_dbl"
              row
              class="ml-4"
              @change="setApplyPoliticaChildSeasonContrateHotel(seasonContrateHotel.price_estancia_dbl)"
            >
              <v-radio
                label="PPPN"
                value="pppn"
              ></v-radio>
              <v-radio
                label="PPHN"
                value="pphn"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-switch
              v-if="showTarifa"
              v-model="seasonContrateHotel.is_tarifa_price_estancia_dbl"
              :label="$t('lbl.tarifa')"
              hide-details
              @click="setTarifa()"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="mt-0"
      >
        <v-row>
          <v-col
            v-for="(season, indR) in seasonsContrateHotel"
            :key="indR"
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="season.name"
              class="my-input"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    showPPP: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    showTarifa: {
      type: Boolean,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      allRooms: [],
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
    }
  },
  computed: {
    ...mapState({
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
      seasonContrateHotel: state => state.app.seasonContrateHotel,
      pricesDBLRoomsSeasonContrateHotel: state => state.app.pricesDBLRoomsSeasonContrateHotel,
    }),
  },
  methods: {
    ...mapMutations(['setApplyPoliticaChildSeasonContrateHotel']),
    setTarifa() {
      if (this.seasonContrateHotel.is_tarifa_price_estancia_dbl) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.pricesDBLRoomsSeasonContrateHotel.length; index++) {
          this.pricesDBLRoomsSeasonContrateHotel[index].in_pc = false
        }
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
