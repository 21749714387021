<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="room.rooms_id"
          :disabled="$store.state.app.onlyShow"
          :items="roomsFree"
          :label="$t('lbl.rooms')"
          class="my-input mr-2"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          multiple
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.room') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            md="10"
          >
            <v-select
              v-model="room.tipo_cama"
              :disabled="$store.state.app.onlyShow"
              :items="allTipoCama"
              :label="$t('menu.tipoCamas')"
              class="my-input mr-2"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="id"
              multiple
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('menu.tipoCamas') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip
              v-if="!$store.state.app.onlyShow"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1"
                  fab
                  dark
                  x-small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteTipoCamaRoomSeasonContrateHotel(pos)"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.delete') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    allTipoCama: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      tipoCamaRoomsSeasonContrateHotel: state => state.app.tipoCamaRoomsSeasonContrateHotel,
    }),
    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.tipoCamaRoomsSeasonContrateHotel.length; index++) {
            if (this.tipoCamaRoomsSeasonContrateHotel[index].rooms_id.includes(element.id) && index !== this.pos) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      return result
    },
    verifyRoomsFree() {
      const result = []
      const roomsSelect = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
          }
        })
      })

      roomsSelect.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.tipoCamaRoomsSeasonContrateHotel.length; index++) {
            if (this.tipoCamaRoomsSeasonContrateHotel[index].rooms_id.includes(element)) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    if (this.roomsHotel.length === 1) {
      this.room.room_id = this.roomsHotel[0].id
    }

    if (this.roomsSeasonContrateHotel.length === 1) {
      if (this.roomsSeasonContrateHotel[0].rooms_id.length === 1) {
        this.room.rooms_id = this.roomsSeasonContrateHotel[0].rooms_id
      }
    }
  },
  methods: {
    ...mapMutations([
      'deleteTipoCamaRoomSeasonContrateHotel',
      'addTipoCamaRoomSeasonContrateHotel',
    ]),
  },
}
</script>
