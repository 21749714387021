var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{staticClass:"my-input mr-2",attrs:{"disabled":_vm.$store.state.app.onlyShow,"items":_vm.roomsFree,"label":_vm.$t('lbl.rooms'),"outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id","multiple":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.room'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.room.rooms_id),callback:function ($$v) {_vm.$set(_vm.room, "rooms_id", $$v)},expression:"room.rooms_id"}})],1),(!_vm.suplement.is_tarifa)?_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.suplement.rooms.length > 1 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteRoomSuplementsReduccTarifasSeasonContrateHotel({ posSup: _vm.posSuplement, posRoom: _vm.pos })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,1409272348)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e(),(!_vm.$store.state.app.onlyShow && _vm.pos === 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 ml-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary","disabled":_vm.verifyRoomsFree},on:{"click":function($event){return _vm.addRoomSuplementsReduccTarifasSeasonContrateHotel(_vm.posSuplement)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,4102010178)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1):_vm._e(),(_vm.suplement.is_tarifa)?_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-switch',{staticClass:"pt-0 mt-4",attrs:{"label":"Main","hide-details":""},on:{"click":_vm.setIsMain},model:{value:(_vm.room.is_main),callback:function ($$v) {_vm.$set(_vm.room, "is_main", $$v)},expression:"room.is_main"}})],1):_vm._e(),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","md":"1"}},[(!_vm.room.is_main)?_c('b',[_vm._v("%")]):_vm._e(),_c('br'),(!_vm.room.is_main)?_c('v-switch',{staticClass:"pt-0 ml-5 mt-0",attrs:{"hide-details":""},model:{value:(_vm.room.in_pc),callback:function ($$v) {_vm.$set(_vm.room, "in_pc", $$v)},expression:"room.in_pc"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',_vm._l((_vm.room.prices),function(price,indC){return _c('div',{key:indC,staticClass:"mt-2",staticStyle:{"max-width":"90px","margin-left":"20px"}},[_c('v-text-field',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"label":_vm.suplement.is_tarifa
                    ? _vm.room.is_main
                      ? 'Precio'
                      : 'Suplemento'
                    : _vm.suplement.is_reduction
                      ? 'Red.'
                      : 'Sup.',"append-icon":indC === 0 && !_vm.room.is_main ? 'mdi-arrow-right' : '',"outlined":"","dense":"","hide-details":"","prefix":_vm.room.in_pc ? '' : price.price ? (!price.price.includes('N') ? '$' : '') : '$',"suffix":_vm.room.in_pc ? (price.price ? (!price.price.includes('N') ? '%' : '') : '') : ''},on:{"keypress":function($event){return _vm.checkPricePc($event, price.price)},"input":function (val) { return (price.price = price.price.toUpperCase()); },"click:append":function($event){indC === 0 && !_vm.room.is_main
                    ? _vm.replicaPriceSuplementTarifaSeasonContrateHotel({
                      posSuplement: _vm.posSuplement,
                      posRoom: _vm.pos,
                      price: price.price,
                    })
                    : ''}},model:{value:(price.price),callback:function ($$v) {_vm.$set(price, "price", $$v)},expression:"price.price"}})],1)}),0)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }