var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{staticClass:"my-input mr-2",attrs:{"disabled":_vm.$store.state.app.onlyShow,"items":_vm.roomsFree,"label":_vm.$t('lbl.rooms'),"outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id","multiple":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.room'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.room.rooms_id),callback:function ($$v) {_vm.$set(_vm.room, "rooms_id", $$v)},expression:"room.rooms_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[(_vm.suplement.season_rooms.length > 1 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteRoomSuplementsOtrosSeasonPicoSeasonContrateHotel({ posSup: _vm.posSuplement, posRoom: _vm.pos })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,2405180901)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e(),(!_vm.$store.state.app.onlyShow && _vm.pos === 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 ml-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary","disabled":_vm.verifyRoomsFree},on:{"click":function($event){return _vm.addRoomSuplementsOtrosSeasonPicoSeasonContrateHotel(_vm.posSuplement)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,3255261147)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"4"}},[(_vm.room.rooms_id.length > 0)?_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"label":"ADL","outlined":"","dense":"","hide-details":"","append-icon":_vm.hasTeen || _vm.hasMnr ? 'mdi-arrow-right' : '',"prefix":_vm.room.price_adl ? (!_vm.room.price_adl.includes('N') ? '$' : '') : '$'},on:{"keypress":function($event){return _vm.checkPricePc($event, _vm.room.price_adl)},"input":function (val) { return (_vm.room.price_adl = _vm.room.price_adl.toUpperCase()); },"click:append":function($event){_vm.hasTeen || _vm.hasMnr
                        ? _vm.replicaSuplementsOtrosSeasonPicoSeasonContrateHotel({
                          posSuplement: _vm.posSuplement,
                          posSeason: _vm.posSeason,
                          posRoom: _vm.pos,
                          price: _vm.room.price_adl,
                        })
                        : ''}},model:{value:(_vm.room.price_adl),callback:function ($$v) {_vm.$set(_vm.room, "price_adl", $$v)},expression:"room.price_adl"}})],1),(_vm.hasTeen || _vm.hasMnr)?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"2"}},[(!_vm.suplement.is_tarifa)?_c('span',[_vm._v("%")]):_vm._e(),_c('br'),(!_vm.suplement.is_tarifa)?_c('v-switch',{staticClass:"pt-0 mt-0",attrs:{"hide-details":""},model:{value:(_vm.room.in_pc),callback:function ($$v) {_vm.$set(_vm.room, "in_pc", $$v)},expression:"room.in_pc"}}):_vm._e()],1):_vm._e(),(_vm.hasTeen)?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"label":"Teen","outlined":"","dense":"","hide-details":"","prefix":_vm.room.in_pc
                        ? ''
                        : _vm.room.price_teen
                          ? !_vm.room.price_teen.includes('N') && !_vm.room.price_teen.includes('F')
                            ? '$'
                            : ''
                          : '$',"suffix":_vm.room.in_pc
                        ? _vm.room.price_teen
                          ? _vm.room.price_teen.includes('N') || _vm.room.price_teen.includes('F')
                            ? ''
                            : '%'
                          : '%'
                        : ''},on:{"keypress":function($event){return _vm.checkPriceMr($event, _vm.room.price_teen)},"input":function (val) { return (_vm.room.price_teen = _vm.room.price_teen.toUpperCase()); }},model:{value:(_vm.room.price_teen),callback:function ($$v) {_vm.$set(_vm.room, "price_teen", $$v)},expression:"room.price_teen"}})],1):_vm._e(),(_vm.hasMnr)?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"label":"MNR","outlined":"","dense":"","hide-details":"","prefix":_vm.room.in_pc
                        ? ''
                        : _vm.room.price_mnr
                          ? !_vm.room.price_mnr.includes('N') && !_vm.room.price_mnr.includes('F')
                            ? '$'
                            : ''
                          : '$',"suffix":_vm.room.in_pc
                        ? _vm.room.price_mnr
                          ? _vm.room.price_mnr.includes('N') || _vm.room.price_mnr.includes('F')
                            ? ''
                            : '%'
                          : '%'
                        : ''},on:{"keypress":function($event){return _vm.checkPriceMr($event, _vm.room.price_mnr)},"input":function (val) { return (_vm.room.price_mnr = _vm.room.price_mnr.toUpperCase()); }},model:{value:(_vm.room.price_mnr),callback:function ($$v) {_vm.$set(_vm.room, "price_mnr", $$v)},expression:"room.price_mnr"}})],1):_vm._e(),(_vm.hasMnr)?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"label":"INF","outlined":"","dense":"","hide-details":"","prefix":_vm.room.in_pc
                        ? ''
                        : _vm.room.price_inf
                          ? !_vm.room.price_inf.includes('N') && !_vm.room.price_inf.includes('F')
                            ? '$'
                            : ''
                          : '$',"suffix":_vm.room.in_pc
                        ? _vm.room.price_inf
                          ? _vm.room.price_inf.includes('N') || _vm.room.price_inf.includes('F')
                            ? ''
                            : '%'
                          : '%'
                        : ''},on:{"keypress":function($event){return _vm.checkPriceMr($event, _vm.room.price_inf)},"input":function (val) { return (_vm.room.price_inf = _vm.room.price_inf.toUpperCase()); }},model:{value:(_vm.room.price_inf),callback:function ($$v) {_vm.$set(_vm.room, "price_inf", $$v)},expression:"room.price_inf"}})],1):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }